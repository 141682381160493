/* ----------------------------- *
 *           DROPDOWN            *
 * ----------------------------- */

.ui.dropdown .menu > .item {
  font-size: var(--font-size-default)
}

.ui.inline.dropdown .menu {
  border-color: #FAFAFA;
  border-radius: 0;
}
