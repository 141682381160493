/* ----------------------------- *
 *           MENU BTN            *
 * ----------------------------- */

 .menu-btn {
  --menu-transition-duration: 0.075s;
  --ease-in-function: easeInQuart;
  --ease-out-function: easeOutQuart;
  --menu-color: var(--color-black);
  --menu-line-width: 1.8rem;
  --menu-line-height: 0.2rem;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  @insert .reset-btn;
  width: 4rem;
  height: 4rem;

  @include-media('>=desktop') {
    display: none;
  }
}

.menu-btn__inner {
  display: inline-block;
  position: relative;
  width: var(--menu-line-width);
  height: var(--menu-line-height);
  transition: transform var(--menu-transition-duration) var(--ease-in-function);
  background-color: var(--menu-color);
}

.menu-btn__inner::before,
.menu-btn__inner::after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  width: var(--menu-line-width);
  height: var(--menu-line-height);
  transition: transform 0.15s ease;
  background-color: var(--menu-color);
}

.menu-btn__inner::before {
  top: -0.5rem;
  transition: top var(--menu-transition-duration) 0.12s ease, opacity var(--menu-transition-duration) ease;
}

.menu-btn__inner::after {
  top: 0.5rem;
  transition: top var(--menu-transition-duration) 0.12s ease, transform var(--menu-transition-duration) var(--ease-in-function);
}

.menu-btn[aria-expanded="true"] {
  .menu-btn__inner {
    transform: rotate(45deg);
    transition-timing-function: var(--ease-out-function);
    transition-delay: 0.12s;

    &::before {
      top: 0;
      transition: top var(--menu-transition-duration) ease, opacity var(--menu-transition-duration) 0.12s ease;
      opacity: 0;
    }

    &::after {
      top: 0;
      transform: rotate(-90deg);
      transition: top var(--menu-transition-duration) ease, transform var(--menu-transition-duration) 0.12s var(--ease-out-function);
    }
  }
}
