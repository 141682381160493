/* ----------------------------- *
 *             HEADER            *
 * ----------------------------- */

.site-header {
  position: sticky;
  z-index: var(--z-index-header);
  top: 0;
  padding-block-end: var(--spacing-sm);
  background-color: var(--color-beige);

  @include-media('>=desktop') {
    position: relative;
  }

  &--over {
    @include-media('>=desktop') {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      background: transparent;
    }
  }
}

.site-header__container {
  position: relative;
  z-index: var(--z-index-header);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem var(--spacing-xs) 0.4rem var(--spacing-sm);

  @include-media('>=desktop') {
    padding: var(--spacing-md) var(--spacing-lg);
  }
}

.site-header__logo {
  display: block;
  width: 11.2rem;

  @include-media('>=desktop') {
    width: 18rem;
  }

  img {
    width: 100%;
  }
}
