/* ----------------------------- *
 *              HOME             *
 * ----------------------------- */

.home-records {
  --content-white-width: 81.67vw;
  --logo-size: 10rem;
  --logo-position-bottom: calc(var(--spacing-sm) * -1);
  --logo-position-left: 0;

  @include-media('>=desktop') {
    --logo-size: 32rem;
    --logo-position-bottom: calc(var(--spacing-2xl) * -1);
  }

  @include-media('>=large') {
    --content-white-width: calc((100vw - var(--container-max-width)) / 2 + var(--container-max-width) * 81.67 / 100);
  }

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 15.2rem; left: var(--container-padding-inline);
    background-color: var(--color-white);
  }

  @include-media('>=desktop') {
    &::before {
      bottom: 0; left: auto;
      width: var(--content-white-width);
    }
  }
}

.home-records__container {
  @include-media('<desktop') {
    display: flex;
    flex-direction: column;
  }
}

.home-records__images {
  position: relative;
  padding: var(--spacing-lg) 0 0 var(--spacing-lg);

  @include-media('<desktop') {
    order: 99;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: var(--logo-position-bottom);
    left: var(--logo-position-left);
    width: var(--logo-size);
    background: url('../images/letter-logo.svg') no-repeat;
    background-size: contain;
    aspect-ratio: 1;
    mix-blend-mode: multiply;
  }

  > * {
    flex-basis: 50%;
  }

  @include-media('>=desktop') {
    padding: 0 5.2rem 0 11.6rem;

    > *:first-child {
      flex-basis: calc(332 / 628 * 100%);
    }

    > *:last-child {
      flex-basis: calc(280 / 628 * 100%);
    }
  }
}
