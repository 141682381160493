/* ----------------------------- *
 *              GRID             *
 * ----------------------------- */

.grid {
  --grid-nb-columns: 12;
  --grid-colum-gap: var(--spacing-sm);

  display: grid;
  grid-gap: var(--grid-column-gap);
  grid-template-columns: repeat(var(--grid-nb-columns), 1fr);
}

.grid--desktop {
  @include-media('<desktop') {
    display: block;
  }
}