/* ----------------------------- *
 *            LAYOUT             *
 * ----------------------------- */

html {
  font-family: var(--font-family-body);
  font-size: 62.5%;
}

body {
  min-height: 100svh;
  background-color: var(--body-background-color);
  font-size: 1.4rem;
  line-height: 1.6;

  &.static-website {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  &.has-menu-opened {
    overflow: hidden;
  }
}

.medium-container {
  width: 100%;
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding-inline: var(--spacing-sm);

  @include-media('>=desktop') {
    padding-inline: var(--spacing-lg);
  }
}

.small-container {
  @insert .medium-container;
  max-width: 97.6rem;
}

.homepage {
  background-color: var(--color-beige);
}
