/* ----------------------------- *
 *              NAV              *
 * ----------------------------- */

.site-nav {
  position: fixed;
  z-index: var(--z-index-menu);
  top: 6.4rem; left: 0;
  width: 100%;
  height: calc(100dvh - var(--header-height));
  margin-inline-start: auto;
  padding: var(--spacing-4xl);
  transform: translateX(100%);
  transition: transform 0.3s;
  background-color: var(--color-beige);

  @include-media('>=desktop') {
    position: static;
    height: auto;
    padding: 0;
    transform: translateX(0);
    background: transparent;
  }

  &.is-visible {
    transform: translateX(0);
  }
}

.site-nav__list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);

  @include-media('>=desktop') {
    flex-direction: row;
    gap: var(--spacing-5xl);
  }
}

.site-nav__item {
  position: relative;
}

.site-nav__parent-link {
  @include-media('>=desktop') {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-sm);
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 100%; left: 0;
      width: 100%;
      height: 1.6rem;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      transition: transform 0.2s;
      background: url('../images/icons/chevron.svg') no-repeat;
    }
  }
}

.site-nav__sublist {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
  padding: var(--spacing-2xl) 0 0 var(--spacing-lg);
  white-space: nowrap;

  @include-media('>=desktop') {
    position: absolute;
    top: calc(100% + var(--spacing-sm)); right: 0;
    gap: var(--spacing-sm);
    padding: var(--spacing-md);
    transform: translateY(2rem);
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
    background-color: var(--color-white);
    pointer-events: none;
  }
}

.site-nav__item--parent:hover .site-nav__sublist,
.site-nav__item--parent:focus .site-nav__sublist,
.site-nav__sublist:has(*:focus) {
  @include-media('>=desktop') {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }
}

.site-nav__item--parent:hover .site-nav__parent-link,
.site-nav__item--parent:focus .site-nav__parent-link {
  @include-media('>=desktop') {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.site-nav__link {
  position: relative;
  padding: var(--spacing-xs) 0;
  outline: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0; left: 0;
    width: 5.6rem;
    height: 0.2rem;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.2s easeOutQuad;
    background-color: var(--color-black);
  }

  &:hover,
  &:focus-visible,
  &:active,
  &.is-selected {
    &::after {
      transform: scaleX(1);
    }
  }

  &.is-selected:focus-visible {
    outline: auto;
  }
}
