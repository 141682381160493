/* ----------------------------- *
*              LIST             *
* ----------------------------- */

[data-route=sylius_shop_product_index] .ui.monster.section.dividing.header {
  @insert .intermediate-title;
  font-family: var(--font-family-heading) !important;
}

[data-route=sylius_shop_product_index] .twelve.wide.column .ui.segment {
  background: var(--color-white);

  input {
    background: var(--color-white);
    font-size: 1.5rem;
  }

  .button {
    background: var(--color-white) !important;

    i {
      color: var(--color-black);
    }
  }
}

[data-route=sylius_shop_product_index] .ui.fluid.vertical.menu .item.header {
  border-color: var(--color-white);
}

[data-route=sylius_shop_product_index] .ui.fluid.vertical.menu .item:not(.header) {
  padding: var(--spacing-sm) !important;

  &::before {
    display: none;
  }
}

.image.scaled {
  overflow: hidden;

  img {
    transition: transform 0.25s;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
