/* ----------------------------- *
 *           ACCORDION            *
 * ----------------------------- */

.ui.accordion .title:not(.ui) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.2rem;
}

.ui.accordion .title:not(.ui) {
  padding-top: var(--spacing-sm);
  border-bottom: 0.1rem rgba(34, 36, 38, 0.15) solid;
}
