/* ----------------------------- *
 *             HEADER            *
 * ----------------------------- */

body:not(.static-website) {
  .site-header__container {
    padding-inline: 0;
  }
}

.site-header__no-space-end {
    padding-block-end: 0;
}
