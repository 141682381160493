/* ------ CONFIG ------ */
@import 'config/_variables';

/* ------ BASE ------ */
@import 'tailwindcss/base';
@import 'base/_fonts';
@import 'base/_reset';

/* ------ LAYOUT ------ */
@import 'layout/_layout';
@import 'layout/_header';
@import 'layout/_menu-btn';
@import 'layout/_nav';
@import 'layout/_footer';

/* ------ UTILS ------ */
@import 'utils/_typography';
@import 'utils/_buttons';
@import 'utils/_grid';

/* ------ COMPONENTS ------ */
@import 'tailwindcss/components';
@import 'components/_hero';
@import 'components/_txt-images';
@import 'components/_look';
@import 'components/_circle-block';
@import 'components/_animations';

/* ------ UTILITIES ------ */
@import 'tailwindcss/utilities';

/* ------ PAGES ------ */
@import 'pages/_home';
@import 'pages/_lookbook';

/* ------ OVERRIDING SYLIUS  ------ */
@import 'eshop/_eshop';
