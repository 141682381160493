/* ----------------------------- *
 *           THANK YOU            *
 * ----------------------------- */

[data-route="sylius_shop_register_thank_you"] > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

[data-route="sylius_shop_order_thank_you"] .sylius-flash-message {
  max-width: var(--container-max-width);
  margin-inline: auto !important;
}

.thankyou {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  background: url('../images/decoration/light-background.webp') no-repeat center center/cover;
  padding: var(--spacing-4xl) 0 var(--spacing-4xl) 0;
}

.thankyou__content {
  padding: var(--spacing-xl) !important;
  background-color: var(--color-white);
}

.thankyou__header {
  text-align: center;
}
