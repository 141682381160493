/* ----------------------------- *
 *              FONTS            *
 * ----------------------------- */

/* ------ JOST ------ */

@font-face {
  font-family: Jost;
  font-weight: 400;
  src: url('../fonts/jost/jost.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Jost;
  font-weight: 700;
  src: url('../fonts/jost/jost-bold.woff2') format('woff2');
  font-display: swap;
}

/* ------ CORMORANT ------ */

@font-face {
  font-family: Cormorant;
  font-weight: 500;
  src: url('../fonts/cormorant/cormorant-medium.woff2') format('woff2');
  font-display: swap;
}
