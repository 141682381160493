/* ----------------------------- *
 *              FORMS            *
 * ----------------------------- */

.ui.input,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"],
.ui.form select,
.ui.input > input,
.ui.form textarea {
  color: var(--color-black);
  font-size: var(--font-size-sm);

  &::placeholder {
    color: var(--color-grey);
  }

  &:focus {
    border-color: var(--color-black) !important;
  }
}

.ui.action.input:not([class*="left action"]) > input:focus {
  border-right-color: var(--color-black) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: var(--color-black) !important;
}

.ui.form .field > label {
  font-size: var(--font-size-default) !important;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.ui.labels .label, .ui.label {
  font-size: var(--font-size-sm);
  font-weight: 400;
}

.ui.large.labels .label, .ui.large.label {
  font-size: var(--font-size-default);
}

.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label::before {
  width: 4.3rem;
  height: 2.3rem;
}

.ui.toggle.checkbox .box::after,
.ui.toggle.checkbox label:after {
  width: 2.3rem;
  height: 2.3rem;
}
