/* ----------------------------- *
 *            CONTACT            *
 * ----------------------------- */

[data-route="sylius_shop_contact_request"] > div,
[data-route="sylius_shop_order_thank_you"] > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: url('../images/decoration/flowers-background-large.webp') no-repeat center center/cover;
  padding: var(--spacing-4xl) 0 var(--spacing-4xl) 0;
}

.contact__content {
  padding: var(--spacing-xl) !important;
  background-color: var(--color-white);
}

.contact__header {
  margin-bottom: var(--spacing-md) !important;
}
