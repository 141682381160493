/* ----------------------------- *
 *          NAVIGATION           *
 * ----------------------------- */

.pusher > #menu .menu .item {
  &:active,
  &:focus-visible,
  &:hover {
    text-decoration: underline;
  }
}

header .ui.menu {
  margin-top: var(--spacing-md);

  &::before {
    box-shadow: none;
  }
}

.ui.large.menu {
  font-size: 1.6rem;
}
.ui.menu {
  font-size: 1.4rem;
}
.ui.breadcrumb {
  margin-top: var(--spacing-lg);
  margin-bottom: 0;
  font-size: 1.4rem;

  a {
    color: inherit;
    text-decoration: underline;

    &:active,
    &:focus-visible,
    &:hover {
      text-decoration: none;
    }
  }

  @include-media('<tablet') {
    padding: 0;
  }
}

.ui.menu .dropdown.item .menu {
    box-shadow: none;
    border-radius: none;
}

.ui.menu > .item {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.ui.menu .ui.dropdown .menu > .item {
    letter-spacing: normal;
}

.ui.vertical.menu {
  background: transparent;
}

#sylius-cart-button {
  padding: 0 !important;
  border: 0 !important;
  font-weight: 700;
  color: var(--color-black) !important;
  background: transparent !important;

  .site-nav__link {
    padding-top: 0 !important;
  }
}

.cart.popup .item {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
