/* ----------------------------- *
*              CART             *
* ----------------------------- */

.cart-header h1.ui.header {
  .content {
    @insert .intermediate-title;
    font-family: var(--font-family-heading) !important;
  }

  > .icon + .content .sub.header {
    margin-top: var(--spacing-md);
    font-size: var(--font-size-default);
  }
}

#sylius-cart-items {
  thead {
    @include-media('<tablet') {
      display: none !important;
    }
  }
  tbody td {
    @include-media('<tablet') {
      text-align: left;
    }
  }
}

.cart-header ~ .ui.grid {
  margin-bottom: var(--spacing-5xl);
}

[data-route=sylius_shop_cart_summary] .sylius-product-name {
  margin: 0;
}

[data-route=sylius_shop_cart_summary] .ui.horizontal.section.divider.header {
  @insert .intermediate-title;
  font-family: var(--font-family-heading) !important;
  font-size: 3rem !important;
  text-align: left;

  @include-media('>desktop') {
    font-size: 4rem !important;
  }
}

[data-route=sylius_shop_cart_summary] .ui.header .sylius-product-variant-code {
  font-size: var(--font-size-sm);
}

[data-route=sylius_shop_cart_summary] .column:last-child .ui.dividing.header,
[data-route=sylius_shop_cart_save] .column:last-child .ui.dividing.header,
[data-route=sylius_shop_cart_checkout] .column:last-child .ui.dividing.header {
  @insert .surtitle;
  font-family: var(--font-family-body) !important;
  margin-top: 1.8rem;
  border-color: var(--color-white) !important;
  font-size: var(--font-size-sm) !important;
}

[data-route=sylius_shop_cart_summary] .column:first-child .table thead th,
[data-route=sylius_shop_cart_save] .column:first-child .table thead th,
[data-route=sylius_shop_cart_checkout] .column:first-child .table thead th,
[data-route=sylius_shop_checkout_complete] form table thead th,
[data-route=sylius_shop_account_order_show] table thead th {
  border-color: var(--color-white) !important;
}

[data-route=sylius_shop_cart_summary] .five.column:last-child,
[data-route=sylius_shop_cart_save] .five.column:last-child,
[data-route=sylius_shop_cart_checkout] .five.column:last-child {
  @include-media('<desktop') {
    padding: 0 var(--spacing-sm) !important;
  }
}
