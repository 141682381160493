/* ----------------------------- *
*             TABLES            *
* ----------------------------- */

.ui.table {
  background: transparent;
  font-size: var(--font-size-sm);
}

.ui.table thead th {
  @insert .surtitle;
  font-size: var(--font-size-sm);
}

.ui.definition.table tr td:first-child:not(.ignored), .ui.definition.table tr td.definition {
  padding-inline: var(--spacing-sm);
  background: transparent;
  font-size: var(--font-size-xs);
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.ui[class*="very basic"].table:not(.sortable):not(.striped) thead tr:first-child th {
  @insert .surtitle;
  font-size: var(--font-size-sm);
}

td[data-label] {
  @include-media('<tablet') {
    padding-top: var(--spacing-xs) !important;
    padding-bottom: var(--spacing-xs) !important;
    text-align: left !important;

    &::before {
      content: attr(data-label);
    }
  }
}
