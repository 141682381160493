/* ----------------------------- *
 *          ANIMATIONS           *
 * ----------------------------- */

.animations {
  [data-animations-target="element"] {
    transition: opacity 0.4s;
  }

  [data-animations-target="element"].is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.animations .medium-container {
    overflow: hidden;
}
