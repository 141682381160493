/* ------ LAYOUT ------ */
@import 'layout/_header';
@import 'layout/_navigation';
@import 'layout/_layout';

/* ------ UTILS ------ */
@import 'utils/_buttons';
@import 'utils/_tables';
@import 'utils/_forms';
@import 'utils/_links';
@import 'utils/_typography';

/* ------ COMPONENTS ------ */
@import 'components/_tabs';
@import 'components/_message';
@import 'components/_cards';
@import 'components/_dropdown';
@import 'components/_accordion';

/* ------ PAGES ------ */
@import 'pages/_home';
@import 'pages/_product';
@import 'pages/_list';
@import 'pages/_cart';
@import 'pages/_checkout';
@import 'pages/_contact';
@import 'pages/_thankyou';

/* SEGMENT */
.ui.segments {
  &--unshadowed {
    box-shadow: none;
  }
}
.ui.segments .segment,
.ui.segment {
  background: transparent;
  font-size: var(--font-size-sm);

  &--unshadowed {
    box-shadow: none;
  }

  &--borderless {
    border: 0;
  }
}

.ui.segments .ui.segment {
  &--borderless {
    border: 0 !important;
  }
}
