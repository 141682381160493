/* ----------------------------- *
 *           TXT IMAGES          *
 * ----------------------------- */

.txt-images {
  @include-media('>=desktop') {
    max-width: 44.8rem;
    margin-inline: auto;
  }
}

.txt-images-large {
  @include-media('>=desktop') {
    max-width: 67.2rem;
    margin-inline: auto;
  }
}

.txt-images__text {
  padding-block-start: var(--spacing-sm);

  @include-media('>=desktop') {
    padding-block-start: var(--spacing-lg);
  }
}

.txt-images__btn {
  margin-block-start: var(--spacing-sm);

  @include-media('>=desktop') {
    margin-block-start: var(--spacing-lg);
  }
}
