/* ----------------------------- *
 *           LOOKBOOK            *
 * ----------------------------- */

.lookbook-footer {
  --letter-size: 14rem;
  --black-block-height: 44rem;
  --img-height: 35.8rem;

  @include-media('>=desktop') {
    --letter-size: 24rem;
  }

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0; bottom: calc(var(--img-height) / 2); left: 0;
    width: 100%;
    background-color: var(--color-black);
  }

  @include-media('>=desktop') {
    &::before {
      bottom: var(--spacing-5xl);
    }
  }
}

.lookbook-footer__container {
  position: relative;
  z-index: var(--z-index-start);
}

.lookbook-footer__text {
  @include-media('>=desktop') {
    margin-top: calc(var(--black-block-height) / 2);
    transform: translateY(-50%);
  }
}

.lookbook-footer__image {
  position: relative;
  width: 25.3rem;

  img {
    width: 100%;

    @include-media('>=desktop') {
      width: auto;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 3rem; left: 20.3rem;
    width: var(--letter-size);
    aspect-ratio: 1;
    opacity: 0.5;
    background: url('../images/letter-logo.svg') no-repeat;
    background-size: contain;
    mix-blend-mode: multiply;
  }

  @include-media('>=desktop') {
    width: auto;

    &::after {
      left: 28.4rem;
    }
  }
}
