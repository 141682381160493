/* ----------------------------- *
 *              TABS             *
 * ----------------------------- */


.ui.tabular.menu .item {
  font-size: var(--font-size-sm);
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  &.active {
    margin: 0;
    background: transparent;
  }
}
