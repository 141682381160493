/* ----------------------------- *
 *             HERO              *
 * ----------------------------- */

.hero {
  --video-width: calc((100vw - var(--container-max-width)) / 2 + var(--container-max-width) * 57.153 / 100);

  position: relative;

  @include-media('>=desktop') {
    height: 90vh;
    overflow: hidden;
  }

  @include-media('>=desktop') {
    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: var(--z-index-start);
      bottom: 25.3%; left: -11.5rem;
      width: 23rem;
      height: 24rem;
      background: url('../images/decoration/flower.png') no-repeat;
      background-size: 100%;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0; left: var(--video-width);
      width: calc(100vw - var(--video-width));
      height: 100%;
      background: url('../images/decoration/flowers-background.webp') no-repeat center center/cover;
    }
  }
}

.hero__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @include-media('>=desktop') {
    flex-direction: row;
  }
}

.hero__video {
  width: 100%;
  aspect-ratio: 375/442;
  overflow: hidden;

  @include-media('>=desktop') {
    position: absolute;
    top: 0; left: 0;
    width: var(--video-width);
    height: 100%;
    aspect-ratio: initial;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.hero__content-grid {
  padding-block-end: 7.3rem;
  background: url('../images/decoration/flowers-background.webp') no-repeat center center/cover;

  @include-media('>=desktop') {
    padding: 0;
  }
}

.hero__text {
  position: relative;
  z-index: 10;
  margin-block-start: -3.5rem;
  margin-inline: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-white);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -2.4rem; right: -0.8rem;
    width: 5.4rem;
    height: 5.6rem;
    background: url('../images/decoration/flower.png') no-repeat;
    background-size: 100%;
  }

  @include-media('>=desktop') {
    margin: 0;
    padding: var(--spacing-xl) var(--spacing-4xl) var(--spacing-2xl);

    &::before {
      top: -7rem; right: auto; left: -6.7rem;
      width: 13.4rem;
      height: 14rem;
    }
  }
}
