/* ----------------------------- *
 *            CARDS              *
 * ----------------------------- */

.ui.cards > .card, .ui.card {
  border-radius: 0;
}

.ui.cards > .card > :first-child,
.ui.card > :first-child {
  border-radius: 0 !important;
}

.card .image,
.card .image .dimmer {
  border-radius: 0 !important;
}
