/* ----------------------------- *
 *            PRODUCT            *
 * ----------------------------- */

[data-route=sylius_shop_product_show] div.ui.container {
  text-align: center;
}

[data-route=sylius_shop_product_show] .ui.form .required.field > label:after {
  display: none;
}

[data-route=sylius_shop_product_show] .ui.breadcrumb {
  width: 100%;
  max-width: 111rem;
  margin: 0 auto !important;
  text-align: left;
}

#sylius-product-name {
  border: 0;
  font-family: var(--font-family-heading) !important;
  font-weight: 400;
  font-size: 6rem;
  line-height: 6rem;
}

[data-route=sylius_shop_product_show] .ui.two.column.stackable.grid > .column:first-child .image img {
  border-radius: 0;
}

#sylius-product-adding-to-cart {
  .field {
    display: flex;
    flex-direction: column;
  }

  #sylius_add_to_cart_cartItem_quantity {
    max-width: 10rem;
  }

  button {
    --btn-background-color: var(--color-beige);
    --btn-color: var(--color-black);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
    width: 100%;
    margin-top: var(--spacing-md);
    padding-left: var(--btn-padding-x) !important;

    > .icon {
      position: static;
      width: auto !important;

      &::before {
        position: static;
        transform: none;
      }
    }
  }
}

[data-route=sylius_shop_product_show] #sylius-product-selecting-variant {
  padding: 0;
}

.sylius-product-name {
  font-size: 1.4rem !important;
  letter-spacing: 0.16em;
  line-height: 1.6em !important;
  text-transform: uppercase;
}

.card .sylius-product-price {
  font-size: 1.4rem !important;
}

[data-route=sylius_shop_product_show] .ui.top.attached.large.tabular.menu::before {
  display: none;
}

[data-route=sylius_shop_product_show] .ui.top.attached.large.tabular.menu .item {
  &.active {
    border-color: var(--color-black);
  }

  &:active,
  &:focus-visible,
  &:hover {
    border-width: 0.3rem !important;
  }
}

.image-product:not(:first-child) {
  margin-block-start: var(--spacing-md);
}

@media (max-width: 768px) {
  .grid-reverse-mobile {
    flex-direction: column-reverse !important;
  }
}
