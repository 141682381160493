/* ----------------------------- *
 *            MESSAGE            *
 * ----------------------------- */

.ui.info.message,
.ui.positive.message {
  --message-color: var(--color-black);
  --message-background-color: var(--color-white);

  padding: var(--spacing-md);
  border: solid var(--message-color) 0.1rem;
  background-color: var(--message-background-color);
  color: var(--message-color);
  box-shadow: none;
}

.ui.info.message {
  --message-color: var(--color-info);
  --message-background-color: var(--color-info-light);
}

.ui.positive.message {
  --message-color: var(--color-success);
}

.ui.message .header + p {
  margin-top: var(--spacing-xs);
}

.ui.info.message .header,
.ui.positive.message .header,
.ui.message .header:not(.ui) {
  color: var(--message-color);
  font-size: var(--font-size-sm);
  letter-spacing: 0.2em;;
  text-transform: uppercase;
}

.ui.message p {
  opacity: 1;
}

.ui.small.message {
  font-size: var(--font-size-xs);
}

.ui.message > .close.icon {
  top: var(--spacing-xs); right: var(--spacing-xs);
  width: 4rem;
  height: 4rem;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 1.7rem; left: 1.2rem;
    width: 1.5rem;
    height: 0.2rem;
    transform: rotate(45deg);
    background-color: var(--message-color) !important;
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.ui.purple.label {
  background-color: var(--color-purple) !important;
  border-color: var(--color-purple) !important;
  color: var(--color-black) !important;
}
