/* ----------------------------- *
 *            BUTTONS            *
 * ----------------------------- */

.ui.button,
.ui.basic.button,
.ui.labeled.button,
.ui.labeled.icon.button,
.buttons .ui.labeled.icon.button {
  @insert .btn;
  border: solid var(--btn-color) 0.1rem !important;
  border-radius: 0 !important;
  color: var(--btn-color) !important;
  font-size: var(--btn-font-size) !important;
  font-weight: 700 !important;

  &:focus-visible,
  &:hover,
  &:active {
    background-color: var(--btn-color, var(--color-black)) !important;
    color: var(--btn-background-color, var(--color-white)) !important;
  }
}

.ui.button.primary,
.ui.basic.button.primary,
.ui.labeled.button.primary,
.ui.labeled.icon.button.primary,
.buttons .ui.labeled.icon.button.primary,
.ui.button.green,
.ui.basic.button.green,
.ui.labeled.button.green,
.ui.labeled.icon.button.green,
.buttons .ui.labeled.icon.button.green,
.ui.button.submit,
.ui.basic.button.submit,
.ui.labeled.button.submit,
.ui.labeled.icon.button.submit,
.buttons .ui.labeled.icon.button.submit {
  border-color: var(--color-black) !important;
  background-color: var(--btn-background-color) !important;
  color: var(--btn-color) !important;

  &:focus-visible,
  &:hover,
  &:active {
    background-color: var(--btn-color, var(--color-black)) !important;
    color: var(--btn-background-color, var(--color-white)) !important;
  }
}

.ui.basic.red.buttons .button, .ui.basic.red.button {
  border-color: var(--color-danger) !important;
  color: var(--color-danger) !important;
  box-shadow: none !important;

  &:focus-visible,
  &:hover,
  &:active {
    background-color: var(--color-danger) !important;
    color: var(--color-white) !important;
  }
}

.ui.button > .icon:not(.button) {
  opacity: 1;
}

.ui.button.button--link {
  padding: 0 !important;
  border: 0 !important;
  font-size: var(--font-size-sm) !important;

  &:active,
  &:focus-visible,
  &:hover {
    text-decoration: underline;
    background-color: transparent !important;
    color: currentColor !important;
  }
}
