/* ----------------------------- *
 *             FOOTER            *
 * ----------------------------- */

.site-footer {
  margin-block-start: var(--spacing-xl);
  background-color: var(--color-white);
  color: var(--color-grey);
  font-size: 1.2rem;

  @include-media('>=desktop') {
    margin-block-start: var(--spacing-5xl);
  }
}

.site-footer__no-space-start {
    margin-block-start: 0;
}

.site-footer__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1.4rem;
  padding: 0.6rem 2.2rem var(--spacing-sm) var(--spacing-lg);

  @include-media('>=desktop') {
    gap: var(--spacing-2xl);
    padding: 2rem 2.2rem 2rem var(--spacing-lg);
  }
}

.site-footer__logo {
  width: 9rem;
}

.site-footer__list {
  display: flex;
  align-items: center;
  gap: 2.2rem;
  margin-inline-start: auto;

  @include-media('>=desktop') {
    order: 999;
  }
}

.site-footer__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(.site-footer__link--icon) {
    outline: 0;

    &:focus-visible,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &--icon {
    min-width: 4rem;
    min-height: 4rem;
  }
}
