/* ----------------------------- *
 *         CIRCLE BLOCK          *
 * ----------------------------- */

.circle-block {
  --circle-top-position: -5.6rem;
  --circle-right-position: auto;
  --circle-x-shift: -4.5rem;
  --circle-left-position: var(--circle-x-shift);
  --circle-size: 25.3rem;
  --circle-opacity: 1;
  --circle-gradient: 315deg, #FFBE69 0%, #FFBFE2 100%;

  &--large {
    --circle-x-shift: -20.5rem;
    --circle-top-position: -16.4rem;
    --circle-size: 35rem;
  }

  &--blue {
    --circle-opacity: 0.2;
    --circle-gradient: 315deg, #2A6790 0%, #D2D3CB 100%;
  }

  &--fuchsia {
    --circle-opacity: 0.6;
    --circle-gradient: 315deg, #FFD6DE 0%, #FE6CAD 100%;
  }

  &--navy {
    --circle-opacity: 0.8;
    --circle-gradient: 315deg, #DCEBFE 0%, #23234F 100%;
  }

  &--right {
    --circle-right-position: var(--circle-x-shift);
    --circle-left-position: auto;
  }

  &--gouache {
    --circle-pattern: url('../images/decoration/gouache.webp');
  }

  &--ombrelles {
    --circle-opacity: 0.8;
    --circle-pattern: url('../images/decoration/ombrelles.webp');
  }

  position: relative;
}

@include-media('>=desktop') {
  .circle-block {
    --circle-top-position: -15.7rem;
    --circle-size: 50rem;
    --circle-x-shift: -11.6rem;

    &--large {
      --circle-top-position: -10.4rem;
    }
  }
}

.circle-block__el {
  position: absolute;
  z-index: -1;
  top: var(--circle-top-position);
  right: var(--circle-right-position);
  left: var(--circle-left-position);
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  opacity: var(--circle-opacity);
  background: linear-gradient(var(--circle-gradient));

  .circle-block--pattern & {
    background-image: var(--circle-pattern);
    background-position: center;
    background-size: cover;
  }
}
