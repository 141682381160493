/* ----------------------------- *
 *             LAYOUT            *
 * ----------------------------- */

body *:not(i),
.ui,
.ui.header {
  font-family: var(--font-family-body) !important;
}

.ui.container {
  width: 100% !important;
  max-width: var(--container-max-width) !important;
  margin: 0 auto auto auto !important;
  padding-inline: var(--spacing-sm);

  @include-media('>=desktop') {
    padding-inline: var(--spacing-lg);
  }
}

body.pushable, body.pushable .pusher {
  background-color: var(--body-background-color) !important;

  > div {
    margin-bottom: auto;
  }
}

.pusher > #menu::after {
  background-color: #FFF;
}
