/* ----------------------------- *
 *            BUTTONS            *
 * ----------------------------- */

.reset-btn {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

.btn {
  --btn-padding-y: 1.4rem;
  --btn-padding-x: var(--spacing-md);
  --btn-font-size: 1.4rem;
  --btn-background-color: var(--color-white);
  --btn-color: var(--color-black);

  display: inline-block;
  @insert .reset-btn;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  transition: background-color 0.1s, color 0.1s;
  border: solid var(--color-black) 0.1rem;
  outline: 0;
  background-color: var(--btn-background-color);
  color: var(--btn-color);
  font-size: var(--btn-font-size);
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 1.4;
  text-transform: uppercase;

  &:focus-visible,
  &:hover,
  &:active {
    background-color: var(--btn-color, var(--color-black));
    color: var(--btn-background-color, var(--color-white));
  }
}

.btn--beige {
  --btn-background-color: var(--color-beige);
  --btn-color: var(--color-black);
}

.btn--small {
  --btn-padding-y: 1.2rem;
  --btn-padding-x: var(--spacing-sm);
  --btn-font-size: 1.2rem;
}