/* ----------------------------- *
 *              HOME             *
 * ----------------------------- */

@media (min-width: 992px) {
  .homepage .featured.column .card .content {
    text-align: left;
  }
}

[data-route="sylius_shop_homepage"] .ui.huge.header {
  margin-top: var(--spacing-2xl);
  margin-bottom: var(--spacing-2xl);
  font-family: var(--font-family-heading) !important;
  font-size: 4rem !important;
  font-weight: 500;
  text-align: center;

  &::after {
    display: none;
  }

  span {
    display: inline-block;
    margin-bottom: var(--spacing-sm);
    font-weight: 700;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
}
