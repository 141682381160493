/* ----------------------------- *
 *            CHECKOUT           *
 * ----------------------------- */

[data-route^=sylius_shop_checkout_] .ui.center.aligned.icon.header,
[data-route^=sylius_shop_checkout_] .ui.center.aligned.icon.header + .divider + .segment {
  background-color: var(--color-white);
}

[data-route^=sylius_shop_checkout_] .steps .step.completed i::before {
  color: var(--color-success) !important;
}

.ui.steps {
  border-radius: 0;
}

.ui.steps .step {
  justify-content: flex-start;
  gap: var(--spacing-md);
  padding: var(--spacing-md) var(--spacing-lg);

  &:first-child {
    border-radius: 0;
  }

  .title {
    font-size: var(--font-size-sm);
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  &.active {
    background: #FAFAFA;

    &::after {
      background: #FAFAFA;
    }

    .title {
      color: var(--color-black) !important;
    }
  }

  .description {
    font-size: var(--font-size-sm);
  }

  > .icon {
    margin-right: 0;
  }
}

.ui.items > .item > .content > .description {
  font-size: var(--font-size-sm);
}

form[name="sylius_checkout_complete"] {
  .ui.dividing.header {
    font-family: var(--font-family-body) !important;
    font-size: var(--font-size-md) !important;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
}

form[name="sylius_checkout_address"] #sylius-billing-address,
form[name="sylius_checkout_address"] #sylius-shipping-address {
  padding: var(--spacing-md);
  background-color: var(--color-white);

  .ui.dividing.header {
    margin-top: 0;
    margin-bottom: var(--spacing-md);
  }
}

[data-route^=sylius_shop_checkout_] .ui.center.aligned.icon.header,
[data-route^=sylius_shop_checkout_] .ui.center.aligned.icon.header + .divider + .segment {
  border-radius: 0;
}

[data-route=sylius_shop_checkout_complete] form {
  > .ui.segment {
    margin-top: var(--spacing-md);
    border: 0;
    border-radius: 0;
    background-color: var(--color-white);
    box-shadow: none;
  }

  > .ui.table {
    padding: var(--spacing-md);
    background-color: var(--color-white);

    thead {
      @include-media('<tablet') {
        display: none;
      }
    }

    thead th {
      border-color: var(--color-black) !important;
    }
  }
}
