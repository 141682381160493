/* ----------------------------- *
 *          TYPOGRAPHY           *
 * ----------------------------- */

.surtitle {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.main-title {
  font-family: var(--font-family-heading) !important;
  font-size: 5.6rem;
  font-weight: 500;
  line-height: 0.8;

  @include-media('>=desktop') {
    font-size: 11.2rem;
  }
}

.big-title {
  font-family: var(--font-family-heading) !important;
  font-size: 8rem;
  font-weight: 500;
  line-height: 1;

  @include-media('>=desktop') {
    font-size: 14rem;
    line-height: 0.8;
  }
}

.second-title {
  font-family: var(--font-family-heading) !important;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1;

  @include-media('>=desktop') {
    font-size: 8rem;
  }
}

.intermediate-title {
  font-family: var(--font-family-heading) !important;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;

  @include-media('>=desktop') {
    font-size: 6rem;
  }
}

.third-title {
  font-family: var(--font-family-heading) !important;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1;
}

.text-content {
  p:not(:last-child) {
    margin-block-end: var(--spacing-md);
  }
}

.text-lead {
  font-size: 1.8rem;

  p:not(:last-child) {
    margin-block-end: var(--spacing-md);
  }
}
