/* ----------------------------- *
 *             LOOK              *
 * ----------------------------- */

.look__text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding-top: var(--spacing-sm);
}

.look__madein {
  color: var(--color-grey);
  font-size: 1.2rem;
  text-transform: uppercase;
}