/* ----------------------------- *
 *          TYPOGRAPHY           *
 * ----------------------------- */

h1.ui.header {
  @insert .intermediate-title;

  .content {
    font-family: var(--font-family-heading) !important;
  }
}

h1.ui.header .sub.header {
  color: var(--color-black);
  font-size: var(--font-size-default);
}

.ui.dividing.header {
  @insert .intermediate-title;
  font-family: var(--font-family-heading) !important;
  font-size: 5rem !important;
}

.ui.horizontal.list .list > .item, .ui.horizontal.list > .item {
  font-size: var(--font-size-default);
}

.bold-text {
    font-weight: 600;
}

.medium-text {
    font-weight: 500 !important;
}

h1.ui.header .heading-font {
  font-family: var(--font-family-heading) !important;
}

.ui.horizontal.section.divider.header::after {
  display: none;
}
